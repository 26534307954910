import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import mapScale from "./images/mapScale.png";
import mapScale2 from "./images/sub-images/map-scale-activity-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class MapScale extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Map Scale";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Map Scale
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={mapScale} alt="map scale math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                The first portion of this lesson teaches the names and locations
                of the continents. Students will construct their own map of the
                world by coloring, cutting, and gluing continents, ocean labels,
                and a map compass.
              </p>
              <p>
                The second portion of the lesson teaches map scale. Students
                will be selecting a map of their choice from a social studies
                textbook and using the map's scale to convert map distances into
                “real life” distances.
              </p>
              <div className="center">
                <h2
                  style={{
                    fontSize: "18pt",
                    fontWeight: "600",
                    marginBottom: "0px",
                  }}
                >
                  Creating a Map of the World
                </h2>
                
              </div>
              <p>
              Each student starts by folding a 12" x 18" construction paper in half twice. After unfolding the paper, each student uses a dark marker and ruler to draw lines on top of the fold marks. These lines will form the equator and prime meridian of each student's map.
              </p>
              <p className="center"><img src={mapScale2} /></p>
              <p>
              Students should color the continents (page 115-117) before cutting them out. Cutting along the wiggly boarders may prove difficult, so tell students to work slowly and patiently. The teacher should hang a previously constructed map at the front of the room so students know where on their construction paper to glue the continents, ocean labels, and compass. Students should consult with the teacher before applying glue.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Map Scale
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={mapScale} alt="map scale math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
              <p>
                The first portion of this lesson teaches the names and locations
                of the continents. Students will construct their own map of the
                world by coloring, cutting, and gluing continents, ocean labels,
                and a map compass.
              </p>
              <p>
                The second portion of the lesson teaches map scale. Students
                will be selecting a map of their choice from a social studies
                textbook and using the map's scale to convert map distances into
                “real life” distances.
              </p>
              <div className="center">
                <h2
                  style={{
                    fontSize: "18pt",
                    fontWeight: "600",
                    marginBottom: "0px",
                  }}
                >
                  Creating a Map of the World
                </h2>
                
              </div>
              <p>
              Each student starts by folding a 12" x 18" construction paper in half twice. After unfolding the paper, each student uses a dark marker and ruler to draw lines on top of the fold marks. These lines will form the equator and prime meridian of each student's map.
              </p>
              <p className="center"><img className="smallerWidth" src={mapScale2} /></p>
              <p>
              Students should color the continents (page 115-117) before cutting them out. Cutting along the wiggly boarders may prove difficult, so tell students to work slowly and patiently. The teacher should hang a previously constructed map at the front of the room so students know where on their construction paper to glue the continents, ocean labels, and compass. Students should consult with the teacher before applying glue.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default MapScale;
