import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import percents from "./images/percents.png";
import percents2 from "./images/sub-images/percent-activity-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Percents extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Percents";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Percents
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={percents} alt="percents math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This project teaches students how to convert fractions into
                decimals and percents. The project also requires students to cut
                out and color sheets of paper that prove visually the
                equivalence between original fractions and their converted
                values.
              </p>
              <p>
                Unlike most other Math Art projects, the teacher should not show
                her completed project until after the students have completed
                their own projects.
              </p>
              <p>
                The teacher starts the lesson by writing four fractions on the
                board: 12/20, 1/2, 1/5, and 4/5. Ask students, “How do we know
                which of these fractions is the biggest and which is the
                smallest? Let's convert them all into decimals and then into
                percents to find out.”
              </p>
              <p>
              Explain to students that the word “percent” means “out of 100.” The teacher should say, “In order to turn these fractions into a percent, we’ll need to first convert each of them into equivalent fractions with denominators of 100.” The teacher should draw the following equations at the front of the room:
              </p>
              <p className="center"><img src={percents2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Percents
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={percents} alt="percents math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
            <p>
                This project teaches students how to convert fractions into
                decimals and percents. The project also requires students to cut
                out and color sheets of paper that prove visually the
                equivalence between original fractions and their converted
                values.
              </p>
              <p>
                Unlike most other Math Art projects, the teacher should not show
                her completed project until after the students have completed
                their own projects.
              </p>
              <p>
                The teacher starts the lesson by writing four fractions on the
                board: 12/20, 1/2, 1/5, and 4/5. Ask students, “How do we know
                which of these fractions is the biggest and which is the
                smallest? Let's convert them all into decimals and then into
                percents to find out.”
              </p>
              <p>
              Explain to students that the word “percent” means “out of 100.” The teacher should say, “In order to turn these fractions into a percent, we’ll need to first convert each of them into equivalent fractions with denominators of 100.” The teacher should draw the following equations at the front of the room:
              </p>
              <p className="center"><img className="smallerWidth" src={percents2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Percents;
