import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import graphing from "./images/graphing.png";
import graphing2 from "./images/sub-images/graphing-activity-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Graphing extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Graphing";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Graphing
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={graphing} alt="graphing math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                The purpose of this project is to teach students how to use the
                same data to make three different graphs: the bar graph, the
                line graph, and the circle graph.
              </p>
              <p>
                The teacher begins the lesson by explaining the difference
                between data and graphs. Data is information, usually in number
                form. Graphs are a way of displaying that information so that
                it's easier to understand.
              </p>
              <p>
              Use an already completed project to show student that the bar graph is ideal for easily comparing differences in number or size. The line graph is ideal for indicating change over time. The pie graph is ideal for comparing parts of the data to the data as a whole (e.g., one can readily see that approximately one-fourth of all students voted Halloween as their favorite holiday).
              </p>
              <p>
              When displaying the completed project to students, make sure they understand that all three graphs are displaying the same data. The teacher should also write the data at the front of the room in chart form.
              </p>
              <p className="center"><img src={graphing2} /></p>
              <p>
              Although it's usually best to use student-collected data for such lessons, for this project the above fake data is recommended. For one, the total number of votes equals 30, which is necessary if students are to use the blank circle graph provided (page 132). Also, since holiday data can be arranged in chronological order, it is suited for line graphs, which usually emphasize change over time. However, feel free to replace some holidays with ones more appropriate to the religions and cultures of your students.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Graphing
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={graphing} alt="graphing math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
              <p>
                The purpose of this project is to teach students how to use the
                same data to make three different graphs: the bar graph, the
                line graph, and the circle graph.
              </p>
              <p>
                The teacher begins the lesson by explaining the difference
                between data and graphs. Data is information, usually in number
                form. Graphs are a way of displaying that information so that
                it’s easier to understand.
              </p>
              <p>
              Use an already completed project to show student that the bar graph is ideal for easily comparing differences in number or size. The line graph is ideal for indicating change over time. The pie graph is ideal for comparing parts of the data to the data as a whole (e.g., one can readily see that approximately one-fourth of all students voted Halloween as their favorite holiday).
              </p>
              <p>
              When displaying the completed project to students, make sure they understand that all three graphs are displaying the same data. The teacher should also write the data at the front of the room in chart form.
              </p>
              <p className="center"><img src={graphing2} className="smallerWidth"/></p>
              <p>
              Although it's usually best to use student-collected data for such lessons, for this project the above fake data is recommended. For one, the total number of votes equals 30, which is necessary if students are to use the blank circle graph provided (page 132). Also, since holiday data can be arranged in chronological order, it is suited for line graphs, which usually emphasize change over time. However, feel free to replace some holidays with ones more appropriate to the religions and cultures of your students.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Graphing;
