import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import transRotReflect from "./images/transRotReflect.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class TranRotReflect extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title =
      "Math Art Projects | Reflections, Translations, & Rotations";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Reflections, Translations, & Rotations
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img
              src={transRotReflect}
              alt="reflections translations rotations math art project"
            />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson helps students understand and remember three
                mathematical terms they might ordinarily find intimidating:
                reflection, translation, and rotation. In short, they will:
              </p>
              <ol className="normal">
                <li>
                  Trace half of a lion face three times onto tracing paper.
                </li>
                <li>Cut out each traced image.</li>
                <li>
                  Perform a reflection, translation, or rotation with each
                  traced image.
                </li>
                <li>
                  Glue each traced image in its appropriate position next to one
                  of the original three half-face photographs.
                </li>
              </ol>
              <p>
              Before students begin working, the teacher needs to explain the three words using her previously constructed project as a reference. The traced half of each picture must be unattached so that the teacher can demonstrate how "flipping" an image creates a reflection, "sliding" an image creates a translation, and "turning" an image creates a rotation.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Reflections, Translations, & Rotations
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img
              src={transRotReflect}
              alt="reflections translations rotations math art project"
              style={{ width: "270px" }}
            />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson helps students understand and remember three
                mathematical terms they might ordinarily find intimidating:
                reflection, translation, and rotation. In short, they will:
              </p>
              <ol>
                <li>
                  Trace half of a lion face three times onto tracing paper.
                </li>
                <li>Cut out each traced image.</li>
                <li>
                  Perform a reflection, translation, or rotation with each
                  traced image.
                </li>
                <li>
                  Glue each traced image in its appropriate position next to one
                  of the original three half-face photographs.
                </li>
              </ol>
              <p>
              Before students begin working, the teacher needs to explain the three words using her previously constructed project as a reference. The traced half of each picture must be unattached so that the teacher can demonstrate how "flipping" an image creates a reflection, "sliding" an image creates a translation, and "turning" an image creates a rotation.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default TranRotReflect;
