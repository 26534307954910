import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Intro extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Addition";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Introduction
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <p>
              This book has a simple title because a simple idea led me to write it. In the summer of 2006, I decided that my math instruction must be enhanced through integration with art. I did not decide that it could or should be enhanced; I decided it must be enhanced.
              </p>
              <p>
I had just finished my first year as a math "enrichment" teacher in the central Bronx. Rather than have my own classroom, my job was to teach supplemental math to third, fourth, and fifth graders. Every week for ten months I had rotated amongst ten different classrooms consisting of more than 200 students. There was no pre-determined curriculum for me to teach, so I was asked to piece together my own curriculum using lessons from whatever sources I could get my hands on. Not surprisingly, my first year as a teacher was characterized by classroom discipline problems, unmotivated students, concerned supervisors and colleagues, and my own ineffective math lessons.
</p>
              <p>
Fortunately, my principal asked me to come back for a second year. Believing that the upcoming year might be my last chance to prove myself as a teacher, I decided that incorporating art—a subject I knew elementary students loved—into my math lessons had a good chance of solving many of my problems.
</p>
              <p>
A tenet of good lesson design is ensuring that students understand the "objective" of a lesson from its start. Therefore, I decided that if my students believed that the objective of a math lesson was creating an art project—in addition to learning a math concept—they would be more motivated to work hard and less motivated to misbehave.
</p>
              <p>
This is why every lesson in this book requires that the teacher construct the required art project before starting to teach the lesson. Most students, in order to get excited about completing an art project, need to see a prototype of the project before beginning work.
</p>
              <p>
Other ideas also led me to incorporate art into math instruction. The simple axiom "A picture is worth a thousand words" supported my belief that visual representations of math concepts, even if they took students multiple class periods to construct, would save instructional time in the long run. This would prove especially true if the construction of each project improved students' retention of the concepts being taught.
</p>
              <p>
An art-integrated curriculum, with a visual rather than verbal emphasis, also seemed likely to improve my instruction of English language learners, who made up a large portion of the students I taught. To further assist in this regard, I gave each lesson a simple title ("Time," "Symmetry," "Place Value,"; etc.), which I would always write on the board at the beginning of each lesson.
</p>
              <p>
Indeed, my desire for simplicity influenced not only the title of each art-inspired lesson, but also the content of each lesson. Many of the students I taught were far below grade level in math. In order to help these students catch up, I would need to target fundamental math concepts directly, even if that meant temporarily glossing over some complexities. Lessons that tried to teach too much would risk jeopardizing the sense of accomplishment that students received as a result of finishing a project and mastering a core math concept. In other words, I developed Math Art as a supplemental curriculum meant to enhance math instruction, not as a total replacement for traditional lessons.
</p>
              <p>
Since Math Art was created as a supplemental curriculum, it does not strictly abide by the teaching philosophies of either "traditionalist" math instruction or "reform" math instruction. Rather, it shares characteristics with both instructional approaches. Like traditionalist teaching, Math Art calls for simple and direct instruction that aims to help students memorize fundamental mathematical concepts. However, like reform instruction, Math Art attempts to make the process of learning mathematics more exciting and meaningful for students. Essentially, my curriculum makes the case against purist teaching philosophies that argue for the exclusive use of a single theory of math education.
</p>
              <p>
Finally, because I was well aware of the preciousness of a teacher's time, all the projects in Math Art require materials that are readily available in a typical elementary school. Each lesson requires only a few of the following: markers, crayons, rulers, scissors, pencils, construction paper, glue sticks, overhead transparencies, a stapler, protractors, chart paper, tape, note cards, tracing paper, plastic coins, and brass tacks.
</p>
              <p>
When I introduced my new curriculum at the start of the 2006-2007 school year, the response from colleagues, supervisors, and most importantly students, was overwhelmingly positive. I would only need to reintroduce myself to students—many of whom knew me from the previous year—to see a change in their attentiveness: "Last year, Mr. Brewer was your math teacher. But this year, Mr. Brewer is your math art teacher!" Whereas during my first year I would enter a classroom and sometimes hear groans coming from students, during my second year I often heard cheers. At parent-teacher conference night, students would enthusiastically introduce me to their parents—sometimes as their math teacher, sometimes as their art teacher.
</p>
              <p>
I do not yet have my own statistics that can provide concrete evidence of Math Art's ability to improve students' achievement in math. Most of my evidence is anecdotal. However, in the process of putting my curriculum into book form, I discovered significant research that supports the integration of art into other subjects:
</p>
              <p>
    Standardized test scores of students in 23 arts-integrated schools in Chicago, Illinois, most serving low-income students, rose as much as two times faster than the scores of youth in more traditional schools (Catterall & Waldorf, 1999).
    </p>
              <p>
    A study of a Minneapolis, Minnesota arts integration program showed that the program had the greatest effect on disadvantaged learners. Low-performing students in these programs consistently defied teachers' expectations as they found pathways to success through the arts that had eluded them in conventional classrooms. Many of these students went from being withdrawn or disruptive to becoming active and productive class members (Ingram & Seashore, 2003).
    </p>
              <p>
    Achievement in mathematics for English language learners was found to increase when visual representations were included as a regular component of instruction. Visual representations were found to assist students in developing a deeper understanding of mathematics and increase retention of information (Gerlic & Jausovic; Marzano, Norford, Paynter, Pickering, & Gaddy, 2001).
    </p>
              <p>
    Educational programs incorpoarting art were associated with academic gains across the curriculum as reflected in standardized test scores, and they appeared to have a more powerful effect on the achievement of struggling students than more conventional arts education programs do (Rabkin & Redmond, 2004).
    </p>
              <p>
Unfortunately, over the last few decades, funding for art instruction in schools has steadily decreased because of a greater concern for academic fundamentals. Rather than view art education as separate from academics, schools should begin combining the two for the benefit of both. Integration of art into traditional subject matter is an obvious way to improve academic achievement in underperforming schools while simultaneously making them more pleasant places to teach and learn. While not all art-integration curriculums need to look exactly like mine, all curriculums need some form of art-integration.
</p>
              <p>

References
</p>
              <p>
Catterall, J., & Waldorf, L. (1999). Chicago arts partnerships in education: Summary evaluation. In E.B. Fiske (Ed.), Champions of change: The impact of the arts on learning. Washington, DC: Arts Education Partnership.
</p>
              <p>
Ingram, D., & Seashore, K. (2003). Arts for academic achievement: Summative evaluation report. Unpublished report. Center for Applied Research and Education Improvement, College of Education and Human Development, University of Minnesota.
</p>
              <p>
Marzano, Robert J., Jennifer S. Norford, Diane E. Paynter, Debra J. Pickering, and Barbara B. Gaddy, eds. Handbook for Classroom Instruction that Works. Alexandria, VA: Association for Supervision and Curriculum Development, 2001.
</p>
              <p>
Rabkin, N., & Redmond, R. (2004). Putting the arts in the picture. In N. Rabkin & R. Redmond (Eds.), Putting the arts in the picture: Reframing education in the 21st century. Chicago: Columbia College Chicago.
              </p>

              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Introduction
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >

            <div style={{ textAlign: "left" }}>
            <p>
              This book has a simple title because a simple idea led me to write it. In the summer of 2006, I decided that my math instruction must be enhanced through integration with art. I did not decide that it could or should be enhanced; I decided it must be enhanced.
              </p>
              <p>
I had just finished my first year as a math "enrichment" teacher in the central Bronx. Rather than have my own classroom, my job was to teach supplemental math to third, fourth, and fifth graders. Every week for ten months I had rotated amongst ten different classrooms consisting of more than 200 students. There was no pre-determined curriculum for me to teach, so I was asked to piece together my own curriculum using lessons from whatever sources I could get my hands on. Not surprisingly, my first year as a teacher was characterized by classroom discipline problems, unmotivated students, concerned supervisors and colleagues, and my own ineffective math lessons.
</p>
              <p>
Fortunately, my principal asked me to come back for a second year. Believing that the upcoming year might be my last chance to prove myself as a teacher, I decided that incorporating art—a subject I knew elementary students loved—into my math lessons had a good chance of solving many of my problems.
</p>
              <p>
A tenet of good lesson design is ensuring that students understand the "objective" of a lesson from its start. Therefore, I decided that if my students believed that the objective of a math lesson was creating an art project—in addition to learning a math concept—they would be more motivated to work hard and less motivated to misbehave.
</p>
              <p>
This is why every lesson in this book requires that the teacher construct the required art project before starting to teach the lesson. Most students, in order to get excited about completing an art project, need to see a prototype of the project before beginning work.
</p>
              <p>
Other ideas also led me to incorporate art into math instruction. The simple axiom "A picture is worth a thousand words" supported my belief that visual representations of math concepts, even if they took students multiple class periods to construct, would save instructional time in the long run. This would prove especially true if the construction of each project improved students' retention of the concepts being taught.
</p>
              <p>
An art-integrated curriculum, with a visual rather than verbal emphasis, also seemed likely to improve my instruction of English language learners, who made up a large portion of the students I taught. To further assist in this regard, I gave each lesson a simple title ("Time," "Symmetry," "Place Value,"; etc.), which I would always write on the board at the beginning of each lesson.
</p>
              <p>
Indeed, my desire for simplicity influenced not only the title of each art-inspired lesson, but also the content of each lesson. Many of the students I taught were far below grade level in math. In order to help these students catch up, I would need to target fundamental math concepts directly, even if that meant temporarily glossing over some complexities. Lessons that tried to teach too much would risk jeopardizing the sense of accomplishment that students received as a result of finishing a project and mastering a core math concept. In other words, I developed Math Art as a supplemental curriculum meant to enhance math instruction, not as a total replacement for traditional lessons.
</p>
              <p>
Since Math Art was created as a supplemental curriculum, it does not strictly abide by the teaching philosophies of either "traditionalist" math instruction or "reform" math instruction. Rather, it shares characteristics with both instructional approaches. Like traditionalist teaching, Math Art calls for simple and direct instruction that aims to help students memorize fundamental mathematical concepts. However, like reform instruction, Math Art attempts to make the process of learning mathematics more exciting and meaningful for students. Essentially, my curriculum makes the case against purist teaching philosophies that argue for the exclusive use of a single theory of math education.
</p>
              <p>
Finally, because I was well aware of the preciousness of a teacher's time, all the projects in Math Art require materials that are readily available in a typical elementary school. Each lesson requires only a few of the following: markers, crayons, rulers, scissors, pencils, construction paper, glue sticks, overhead transparencies, a stapler, protractors, chart paper, tape, note cards, tracing paper, plastic coins, and brass tacks.
</p>
              <p>
When I introduced my new curriculum at the start of the 2006-2007 school year, the response from colleagues, supervisors, and most importantly students, was overwhelmingly positive. I would only need to reintroduce myself to students—many of whom knew me from the previous year—to see a change in their attentiveness: "Last year, Mr. Brewer was your math teacher. But this year, Mr. Brewer is your math art teacher!" Whereas during my first year I would enter a classroom and sometimes hear groans coming from students, during my second year I often heard cheers. At parent-teacher conference night, students would enthusiastically introduce me to their parents—sometimes as their math teacher, sometimes as their art teacher.
</p>
              <p>
I do not yet have my own statistics that can provide concrete evidence of Math Art's ability to improve students' achievement in math. Most of my evidence is anecdotal. However, in the process of putting my curriculum into book form, I discovered significant research that supports the integration of art into other subjects:
</p>
              <p>
    Standardized test scores of students in 23 arts-integrated schools in Chicago, Illinois, most serving low-income students, rose as much as two times faster than the scores of youth in more traditional schools (Catterall & Waldorf, 1999).
    </p>
              <p>
    A study of a Minneapolis, Minnesota arts integration program showed that the program had the greatest effect on disadvantaged learners. Low-performing students in these programs consistently defied teachers' expectations as they found pathways to success through the arts that had eluded them in conventional classrooms. Many of these students went from being withdrawn or disruptive to becoming active and productive class members (Ingram & Seashore, 2003).
    </p>
              <p>
    Achievement in mathematics for English language learners was found to increase when visual representations were included as a regular component of instruction. Visual representations were found to assist students in developing a deeper understanding of mathematics and increase retention of information (Gerlic & Jausovic; Marzano, Norford, Paynter, Pickering, & Gaddy, 2001).
    </p>
              <p>
    Educational programs incorpoarting art were associated with academic gains across the curriculum as reflected in standardized test scores, and they appeared to have a more powerful effect on the achievement of struggling students than more conventional arts education programs do (Rabkin & Redmond, 2004).
    </p>
              <p>
Unfortunately, over the last few decades, funding for art instruction in schools has steadily decreased because of a greater concern for academic fundamentals. Rather than view art education as separate from academics, schools should begin combining the two for the benefit of both. Integration of art into traditional subject matter is an obvious way to improve academic achievement in underperforming schools while simultaneously making them more pleasant places to teach and learn. While not all art-integration curriculums need to look exactly like mine, all curriculums need some form of art-integration.
</p>
              <p>

References
</p>
              <p>
Catterall, J., & Waldorf, L. (1999). Chicago arts partnerships in education: Summary evaluation. In E.B. Fiske (Ed.), Champions of change: The impact of the arts on learning. Washington, DC: Arts Education Partnership.
</p>
              <p>
Ingram, D., & Seashore, K. (2003). Arts for academic achievement: Summative evaluation report. Unpublished report. Center for Applied Research and Education Improvement, College of Education and Human Development, University of Minnesota.
</p>
              <p>
Marzano, Robert J., Jennifer S. Norford, Diane E. Paynter, Debra J. Pickering, and Barbara B. Gaddy, eds. Handbook for Classroom Instruction that Works. Alexandria, VA: Association for Supervision and Curriculum Development, 2001.
</p>
              <p>
Rabkin, N., & Redmond, R. (2004). Putting the arts in the picture. In N. Rabkin & R. Redmond (Eds.), Putting the arts in the picture: Reframing education in the 21st century. Chicago: Columbia College Chicago.
              </p>


              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Intro;
