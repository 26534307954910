import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import area from "./images/areaAndPerimeter2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Area extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Area";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Area
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={area} alt="area math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson teaches students to determine the area of unusual
                shapes (shapes other than basic squares and rectangles). It also
                requires them to make symmetrical patterns.
              </p>
              <p>
                The lesson is designed, in part, to provide an alternative to
                the often used, but problematic way of teaching the difference
                between area and perimeter. Sometimes, teachers will say, “When
                finding perimeter, we add; when finding area, we multiply.” If
                this explanation becomes the basis for understanding area and
                perimeter, students are sure to be stumped on more difficult
                problems involving these concepts.
              </p>
              <p>
              Perimeter needs to be understood for what it really is: the repetition of linear units around shapes (see Perimeter lesson, page 32). Likewise, area needs to be understood as the repetition of square units over the surface of a shape. Furthermore, students should recognize that if a surface’s square units are rearranged or broken apart, the area of the surface does not change.
              </p>
              <p>
              The Math Art area lesson allows students to create complex shapes one square inch and one half-square inch at a time. This allows them to find area by simply counting wholes and halves. Gaining such a fundamental understanding of area will better prepare students for future lessons that teach multiplication as another method of finding area.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Area
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={area} alt="area math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
            <p>
                This lesson teaches students to determine the area of unusual
                shapes (shapes other than basic squares and rectangles). It also
                requires them to make symmetrical patterns.
              </p>
              <p>
                The lesson is designed, in part, to provide an alternative to
                the often used, but problematic way of teaching the difference
                between area and perimeter. Sometimes, teachers will say, “When
                finding perimeter, we add; when finding area, we multiply.” If
                this explanation becomes the basis for understanding area and
                perimeter, students are sure to be stumped on more difficult
                problems involving these concepts.
              </p>
              <p>
              Perimeter needs to be understood for what it really is: the repetition of linear units around shapes (see Perimeter lesson, page 32). Likewise, area needs to be understood as the repetition of square units over the surface of a shape. Furthermore, students should recognize that if a surface’s square units are rearranged or broken apart, the area of the surface does not change.
              </p>
              <p>
              The Math Art area lesson allows students to create complex shapes one square inch and one half-square inch at a time. This allows them to find area by simply counting wholes and halves. Gaining such a fundamental understanding of area will better prepare students for future lessons that teach multiplication as another method of finding area.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Area;
