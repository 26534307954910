import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import pplines from "./images/ppLines.png";
import pplines2 from "./images/sub-images/parallel-and-perpendicular-lines-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Time extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Perpendicular & Parallel Lines";
  };

  

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Perpendicular & Parallel Lines
          </h1>

          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img
              src={pplines}
              alt="perpendicular parallel lines math art project"
            />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson teaches students to identify perpendicular and
                parallel lines. Perpendicular and parallel lines are recurrent
                topics. They continuously appear, in one form or another, at all
                levels of mathematics. However, at the elementary school level,
                a basic understanding and familiarity of both terms is usually
                all that is required.
              </p>
              <p>
                Parallel lines can most simply be defined as "lines that are
                side-by-side and never touch, no matter how long we draw them."
                Perpendicular lines can be defined as "lines that make a
                T-shape." Both of these definitions are somewhat
                incomplete—lines can be parallel and still not be side-by-side;
                and perpendicular lines can make an L-shape as a T-shape. Still,
                introducing such complications is best left for later lessons.
              </p>
              <p>
              The teacher should draw examples and counter-examples to help students understand both terms.
              </p>
              <p className="center"><img src={pplines2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Perpendicular & Parallel Lines
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img
              src={pplines}
              alt="perpendicular parallel math art project"
              style={{ width: "270px" }}
            />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson teaches students to identify perpendicular and
                parallel lines. Perpendicular and parallel lines are recurrent
                topics. They continuously appear, in one form or another, at all
                levels of mathematics. However, at the elementary school level,
                a basic understanding and familiarity of both terms is usually
                all that is required.
              </p>
              <p>
                Parallel lines can most simply be defined as "lines that are
                side-by-side and never touch, no matter how long we draw them."
                Perpendicular lines can be defined as "lines that make a
                T-shape." Both of these definitions are somewhat
                incomplete—lines can be parallel and still not be side-by-side;
                and perpendicular lines can make an L-shape as a T-shape. Still,
                introducing such complications is best left for later lessons.
              </p>
              <p>
              The teacher should draw examples and counter-examples to help students understand both terms.
              </p>
              <p className="center"><img className="smallerWidth" src={pplines2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Time;
