import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import angles from "./images/angles2.png";
import angles2 from "./images/sub-images/angle-activity-4.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Angles extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Angles";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Angles
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={angles} alt="angles math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson will involve two activities: the construction of a
                "Paper Protractor" and an "Angle Comparison Strip." The main
                purpose of both these projects is to improve students' ability
                to estimate and compare angle measurements. These skills will
                make later instruction on triangles, protractors, rotations, and
                other topics more effective.
              </p><br/>
              <div className="center">
                <h2
                  style={{
                    fontSize: "18pt",
                    fontWeight: "600",
                    marginBottom: "0px",
                  }}
                >
                  Introduction to the Rotating “Paper Protractor”
                </h2>
                
              </div>
              <p>Introduce the first project to students by simply asking, “What are angles?” Good responses to elicit are “angles are two connected lines,” or “angles are corners.” Draw some examples on the board and show students how to use a paper protractor (constructed beforehand by the teacher) to estimate angle sizes. For instance:</p>
              <p className="center"><img src={angles2} /></p>

              <br /><br/>
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Angles
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img
              src={angles}
              alt="angles math art project"
              style={{ width: "270px" }}
            />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson will involve two activities: the construction of a
                "Paper Protractor" and an "Angle Comparison Strip." The main
                purpose of both these projects is to improve students' ability
                to estimate and compare angle measurements. These skills will
                make later instruction on triangles, protractors, rotations, and
                other topics more effective.
              </p>
              <br/>
              <div className="center">
                <h2
                  style={{
                    fontSize: "18pt",
                    fontWeight: "600",
                    marginBottom: "0px",
                  }}
                >
                  Introduction to the Rotating “Paper Protractor”
                </h2>
                
              </div>
              <p>Introduce the first project to students by simply asking, “What are angles?” Good responses to elicit are “angles are two connected lines,” or “angles are corners.” Draw some examples on the board and show students how to use a paper protractor (constructed beforehand by the teacher) to estimate angle sizes. For instance:</p>
              <p className="center"><img className="smallerWidth" src={angles2} /></p>
              <br /><br/>
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Angles;
