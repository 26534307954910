import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import division from "./images/division.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Time extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Division";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Division
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={division} alt="division math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This project teaches students the three parts of a division
                problem: the dividend, the divisor, and the quotient. More
                specifically, it teaches them that the divisor (the second
                number in a division number sentence) can be understood in two
                different ways: either as the <strong>number of groups</strong>{" "}
                or as the <strong>number in each group</strong>.
              </p>
              <p>
                For instance, take the division sentence 40 ÷ 4 = 10. Here, the
                number 4 can represent either the number of groups that 40 is
                broken into (which would make 4 groups with 10 in each group),
                or the number 4 can represent the number in each group (which
                would make 10 groups with 4 in each group).
              </p>
              <p>
              This distinction can be important in real life situations. Take these two problems for instance:
              </p>
              <ul>
                <li>Jake has 40 M&M’s. He wants to give an equal amount of M&M’s to 4 of his friends. How many M&M’s will each of his 4 friends get?</li>
                <li>Jake has 40 M&M’s. He wants to give 4 M&Ms to as many of his friends as he can. How many of his friends will get 4 M&M’s?</li>
              </ul>
              <p>Both of these problems lead to the number sentence 40 ÷ 4 = 10. However, the real life contexts are totally different. In the first problem, 4 people get 10 M&Ms each. In the second problem, 10 people get 4 M&Ms each.</p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Division
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={division} alt="division math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
              <p>
                This project teaches students the three parts of a division
                problem: the dividend, the divisor, and the quotient. More
                specifically, it teaches them that the divisor (the second
                number in a division number sentence) can be understood in two
                different ways: either as the <strong>number of groups</strong>{" "}
                or as the <strong>number in each group</strong>.
              </p>
              <p>
                For instance, take the division sentence 40 ÷ 4 = 10. Here, the
                number 4 can represent either the number of groups that 40 is
                broken into (which would make 4 groups with 10 in each group),
                or the number 4 can represent the number in each group (which
                would make 10 groups with 4 in each group).
              </p>
              <p>
              This distinction can be important in real life situations. Take these two problems for instance:
              </p>
              <ul>
                <li>Jake has 40 M&M’s. He wants to give an equal amount of M&M’s to 4 of his friends. How many M&M’s will each of his 4 friends get?</li>
                <li>Jake has 40 M&M’s. He wants to give 4 M&Ms to as many of his friends as he can. How many of his friends will get 4 M&M’s?</li>
              </ul>
              <p>Both of these problems lead to the number sentence 40 ÷ 4 = 10. However, the real life contexts are totally different. In the first problem, 4 people get 10 M&Ms each. In the second problem, 10 people get 4 M&Ms each.</p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Time;
