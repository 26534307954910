import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import tellingTime from "./images/tellingTime.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Time extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Time"
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Time
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={tellingTime} alt="telling time math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson helps students visualize the imaginary numbers by
                which minutes are read on a clock. The blue hour hand extends to
                the blue numbers 1-12. The red minute hand extends to the red
                numbers, which count to 55 by fives.
              </p>
              <p>
                Start by asking students which numbers, red or blue, are like
                the numbers on a real clock (blue). Follow by making sure they
                understand the reason that their clock will have additional
                numbers in red.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Time
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={tellingTime} alt="telling time math art project" style={{maxWidth: "270px"}} />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson helps students visualize the imaginary numbers by
                which minutes are read on a clock. The blue hour hand extends to
                the blue numbers 1-12. The red minute hand extends to the red
                numbers, which count to 55 by fives.
              </p>
              <p>
                Start by asking students which numbers, red or blue, are like
                the numbers on a real clock (blue). Follow by making sure they
                understand the reason that their clock will have additional
                numbers in red.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Time;
