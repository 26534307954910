import "./App.css";
import logo from "./images/logo2.png";
import numberChart1 from "./images/chart1.png";
// import numberChart2 from "./images/chart2.png";
import addition from "./images/addition.png";
import angles1 from "./images/angles1.png";
// import angles2 from "./images/angles2.png";
import areaPerimeter1 from "./images/areaAndPerimeter1.png";
import areaPerimeter2 from "./images/areaAndPerimeter2.png";
import circles from "./images/circles.png";
import multiples from "./images/multiples.png";
import division from "./images/division.png";
import fractions from "./images/fractions.png";
import setFractions from "./images/setFractions.png";
import graphing from "./images/graphing.png";
import mapScale from "./images/mapScale.png";
import measurement1 from "./images/measurement1.png";
import measurement2 from "./images/measurement2.png";
import money from "./images/money.png";
import multiplication from "./images/multiplication.png";
import ppLines from "./images/ppLines.png";
import percents from "./images/percents.png";
import placeValue from "./images/placeValue.png";
import prismsPyramids from "./images/prismsPyramids.png";
import shapes from "./images/shapes.png";
import triangles from "./images/triangles.png";
import squareNumbers from "./images/squareNumbers.png";
import symmetry from "./images/symmetry.png";
import transRotReflect from "./images/transRotReflect.png";
// import volume1 from "./images/volume1.png";
import volume2 from "./images/volume2.png";
import tellingTime from "./images/tellingTime.png";
import amazon from "./images/amazon3.png";

import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";

class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Math Art Projects";
    window.addEventListener("scroll", this.props.appState.captureHomeScroll);
    window.scrollTo(0, this.props.appState.homeScrollY);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.props.appState.captureHomeScroll);
  };

  intro = (
    <div
      style={{
        textAlign: "left",
        fontSize: "13.5pt",
      }}
    >
      <p>
        Welcome to the digital home of{" "}
        <span style={{ fontStyle: "italic", fontWeight: "600" }}>
          Math Art: Hands-On Math Activities for Grades 2, 3, and 4
        </span> by Zachary J. Brewer.
        
      </p>

      <p>
        Math Art is a supplemental mathematics curriculum that I developed as an
        elementary math teacher in the Bronx. Like any supplemental lesson book,
        the main purpose of Math Art is to help teachers introduce, reinforce,
        or expand upon the topics their students are required to learn.
        Additionally, by blending the subjects of mathematics and art, the
        curriculum's projects are capable of motivating students, increasing
        student retention of knowledge, and assisting the instruction of visual
        learners, kinesthetic learners, and English language learners.  You can read the book's full introduction <Link to="/intro">here</Link>!
      </p>
      <p>
        Each of the book's math art projects require students to create an
        aesthetically-pleasing product that focuses on a fundamental or "broad"
        mathematical topic. Take a look!
      </p>
    </div>
  );

  render() {
    // console.log(this.props.appState)

    // return (
    //   <div>HOME</div>
    // )
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <img
            style={{ marginBottom: "-8px", marginTop: "30px" }}
            src={logo}
            alt="Math Art Projects"
          />
          <h1
            style={{ fontSize: "34pt", lineHeight: "30px", fontWeight: "600" }}
          >
            Math Art Projects
          </h1>
          <div
            style={{ fontSize: "24pt", lineHeight: "14px", fontWeight: "600" }}
          >
            for Elementary School Students
          </div>
          <br />
          <div
            style={{
              textAlign: "left",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            {this.intro}
          </div>

          <div
            style={{
              fontSize: "14pt",
            }}
          >
            <h2
              style={{
                fontSize: "22pt",
                textAlign: "center",
                fontWeight: "600",
                marginTop: "36px",
                marginBottom: "30px",
                textDecoration: "underline",
              }}
            >
              The Projects
            </h2>

            <div style={{ display: "flex", width: "680px", margin: "auto" }}>
              <div style={{ flex: "50%" }}>
                <Link to="/time">
                  <img
                    src={tellingTime}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Time
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd Grade
                </div>
                <br />
                <Link to="/addition">
                  <img
                    src={addition}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Addition
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd Grade
                </div>
                <br />

                <Link to="/money">
                  <img
                    src={money}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Money
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd & 3rd Grade
                </div>
                <br />
                <Link to="/fractions">
                  <img
                    src={fractions}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Fractions
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd & 3rd Grade
                </div>
                <br />
                <Link to="/inchmeasurement">
                  <img
                    src={measurement2}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Inch Measurement
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd & 3rd Grade
                </div>
                <br />
                <Link to="/multiples">
                  <img
                    src={multiples}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Multiples
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/multiplication">
                  <img
                    src={multiplication}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Multiplication
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/centmeasurement">
                  <img
                    src={measurement1}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Centimeter Measurment
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/symmetry">
                  <img
                    src={symmetry}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Symmetry
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/numberchart">
                  <img
                    src={numberChart1}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    The 100 Number Chart
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/area">
                  <img
                    src={areaPerimeter2}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Area
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/squarenumbers">
                  <img
                    src={squareNumbers}
                    style={{ width: "200px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Square Numbers
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/pplines">
                  <img
                    src={ppLines}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Perpendicular & Parallel Lines
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/perimeter">
                  <img
                    src={areaPerimeter1}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Perimeter
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
              </div>

              {/* ----- NEW COLUMN ----- */}

              <div style={{ flex: "50%" }}>
                <Link to="/shapes">
                  <img
                    src={shapes}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Shapes
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/placevalue">
                  <img
                    src={placeValue}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Place Value
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/angles">
                  <img
                    src={angles1}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Angles
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/graphing">
                  <img
                    src={graphing}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Data and Graphs
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/triangles">
                  <img
                    src={triangles}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Similar Triangles
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  2nd, 3rd, & 4th Grade
                </div>
                <br />
                <Link to="/volume">
                  <img
                    src={volume2}
                    style={{ width: "180px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Volume
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  3rd & 4th Grade
                </div>
                <br />
                <Link to="/prismspyramids">
                  <img
                    src={prismsPyramids}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Prisms and Pyramids
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  3rd & 4th Grade
                </div>
                <br />
                <Link to="/transrotreflect">
                  <img
                    src={transRotReflect}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Reflections, Translations, & Rotations
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  3rd & 4th Grade
                </div>
                <br />
                <Link to="/mapscale">
                  <img
                    src={mapScale}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Map Scale
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  3rd & 4th Grade
                </div>
                <br />
                <Link to="/circles">
                  <img
                    src={circles}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Radius of a Circle
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  3rd & 4th Grade
                </div>
                <br />
                <Link to="/percents">
                  <img
                    src={percents}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Percents
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  4th Grade
                </div>
                <br />
                <Link to="/setfractions">
                  <img
                    src={setFractions}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Fractions of a Set
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  4th Grade
                </div>
                <br />
                <Link to="/division">
                  <img
                    src={division}
                    style={{ width: "250px", marginBottom: "-5px" }}
                  />
                  <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                    Division
                  </div>
                </Link>
                <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                  4th Grade
                </div>
                <br />
              </div>
            </div>
            <br />
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "260px" }}
              />
            </a>
          </div>
          <br />
          <br />
          <div style={{ fontSize: "10pt" }}>
            Copyright © 2022 by Zachary J. Brewer
          </div>

          <br />
          <br />
          <br />
          <br />
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <img
            style={{ width: "94%", maxWidth: "400px" }}
            src={logo}
            alt="Math Art"
          />
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              margin: "0px",
              marginBottom: "10px",
              marginTop: "14px",
              lineHeight: "42px",
            }}
          >
            Math Art Projects
          </h1>
          <div
            style={{
              fontSize: "14pt",
              fontWeight: "700",
            }}
          >
            for Elementary School Students
          </div>
          <br />
          {this.intro}
          <div
            style={{
              fontSize: "14pt",
            }}
          >
            <h2
              style={{
                fontSize: "22pt",
                textAlign: "center",
                fontWeight: "600",
                marginTop: "36px",
                marginBottom: "30px",
                textDecoration: "underline",
              }}
            >
              The Projects
            </h2>
            <div>
              <Link to="/time">
                <img
                  src={tellingTime}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Time
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd Grade
              </div>
              <br />
              <Link to="/addition">
                <img
                  src={addition}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Addition
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd Grade
              </div>
              <br />
              <Link to="/money">
                <img
                  src={money}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Money
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd & 3rd Grade
              </div>
              <br />
              <Link to="/fractions">
                <img
                  src={fractions}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Fractions
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd & 3rd Grade
              </div>
              <br />
              <Link to="/inchmeasurement">
                <img
                  src={measurement2}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Inch Measurement
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd & 3rd Grade
              </div>
              <br />
              <Link to="/multiples">
                <img
                  src={multiples}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Multiples
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/multiplication">
                <img
                  src={multiplication}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Multiplication
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/centmeasurement">
                <img
                  src={measurement1}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Centimeter Measurment
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/symmetry">
                <img
                  src={symmetry}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Symmetry
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/numberchart">
                <img
                  src={numberChart1}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  The 100 Number Chart
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/area">
                <img
                  src={areaPerimeter2}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Area
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/squarenumbers">
                <img
                  src={squareNumbers}
                  style={{ width: "200px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Square Numbers
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/pplines">
                <img
                  src={ppLines}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Perpendicular & Parallel Lines
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/perimeter">
                <img
                  src={areaPerimeter1}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Perimeter
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/shapes">
                <img
                  src={shapes}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Shapes
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/placevalue">
                <img
                  src={placeValue}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Place Value
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/angles">
                <img
                  src={angles1}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Angles
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/graphing">
                <img
                  src={graphing}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Data and Graphs
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/triangles">
                <img
                  src={triangles}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Similar Triangles
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                2nd, 3rd, & 4th Grade
              </div>
              <br />
              <Link to="/volume">
                <img
                  src={volume2}
                  style={{ width: "180px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Volume
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                3rd & 4th Grade
              </div>
              <br />
              <Link to="/prismspyramids">
                <img
                  src={prismsPyramids}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Prisms and Pyramids
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                3rd & 4th Grade
              </div>
              <br />
              <Link to="/transrotreflect">
                <img
                  src={transRotReflect}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Reflections, Translations, & Rotations
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                3rd & 4th Grade
              </div>
              <br />
              <Link to="/mapscale">
                <img
                  src={mapScale}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Map Scale
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                3rd & 4th Grade
              </div>
              <br />
              <Link to="/circles">
                <img
                  src={circles}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Radius of a Circle
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                3rd & 4th Grade
              </div>
              <br />
              <Link to="/percents">
                <img
                  src={percents}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Percents
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                4th Grade
              </div>
              <br />
              <Link to="/setfractions">
                <img
                  src={setFractions}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Fractions of a Set
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                4th Grade
              </div>
              <br />
              <Link to="/division">
                <img
                  src={division}
                  style={{ width: "250px", marginBottom: "-5px" }}
                />
                <div style={{ fontSize: "18pt", marginBottom: "-1px" }}>
                  Division
                </div>
              </Link>
              <div style={{ fontWeight: "600", fontSize: "12.5pt" }}>
                4th Grade
              </div>
              <br />
            </div>
          </div>
          <br />
          <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
            <img
              src={amazon}
              alt="Buy book on Amazon"
              style={{ width: "200px" }}
            />
          </a>
          <br />
          <br />
          <br />
          <div style={{ fontSize: "10pt" }}>
            Copyright © 2022 by Zachary J. Brewer
          </div>
          <br />
          <br />
          <br />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Home;
