import "./App.css";
import Home from "./Home";
import Addition from "./Addition";
import Intro from "./Intro";
import Angles from "./Angles";
import Area from "./Area";
import CentMeasurement from "./CentMeasurement";
import Circles from "./Circles";
import Division from "./Division";
import Fractions from "./Fractions";
import Graphing from "./Graphing";
import InchMeasurement from "./InchMeasurement";
import MapScale from "./MapScale";
import Money from "./Money";
import Multiples from "./Multiples";
import Multiplication from "./Multiplication";
import NumberChart from "./NumberChart";
import Percents from "./Percents";
import Perimeter from "./Perimeter";
import PlaceValue from "./PlaceValue";
import PPLines from "./PPLines";
import PrismsPyramids from "./PrismsPyramids";
import SetFractions from "./SetFractions";
import Shapes from "./Shapes";
import SquareNumbers from "./SquareNumbers";
import Symmetry from "./Symmetry";
import Time from "./Time";
import TransRotReflect from "./TransRotReflect";
import Triangles from "./Triangles";
import Volume from "./Volume";

import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAl-Tmk2lBUe0yJ6gevNLA6F_T3sYtfmS8",
  authDomain: "mathartlessons.firebaseapp.com",
  projectId: "mathartlessons",
  storageBucket: "mathartlessons.appspot.com",
  messagingSenderId: "815423184769",
  appId: "1:815423184769:web:46702851d28c70558c9280",
  measurementId: "G-2TL5VSJZCL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      size: "mobile",
      homeScrollY: 0,
      captureHomeScroll: this.captureHomeScroll,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
    if (window.innerWidth >= 720) {
      this.setState(
        {
          size: "desktop",
        },
        () => console.log(this.state.size)
      );
    } else {
      this.setState(
        {
          size: "mobile",
        },
        () => console.log(this.state.size)
      );
    }
  };

  captureHomeScroll = () => {
    this.setState(
      {
        homeScrollY: Math.round(window.scrollY),
      }
    );
  };

  handleResize = (e) => {
    const w = window.innerWidth;
    // console.log(w)
    // console.log(e)

    if (this.state.size === "desktop" && w < 720) {
      this.setState(
        {
          size: "mobile",
        },
        () => console.log(this.state.size)
      );
    }
    if (this.state.size === "mobile" && w >= 720) {
      this.setState(
        {
          size: "desktop",
        },
        () => console.log(this.state.size)
      );
    }
  };

  render() {
    return (
      <Routes>
        <Route path="/" element={<Home appState={this.state} />} />
        <Route path="/intro" element={<Intro appState={this.state} />} />
        <Route path="/time" element={<Time appState={this.state} />} />
        <Route path="/shapes" element={<Shapes appState={this.state} />} />
        <Route path="/addition" element={<Addition appState={this.state} />} />
        <Route
          path="/placeValue"
          element={<PlaceValue appState={this.state} />}
        />
        <Route path="/money" element={<Money appState={this.state} />} />
        <Route path="/angles" element={<Angles appState={this.state} />} />
        <Route
          path="/fractions"
          element={<Fractions appState={this.state} />}
        />
        <Route path="/graphing" element={<Graphing appState={this.state} />} />
        <Route
          path="/inchmeasurement"
          element={<InchMeasurement appState={this.state} />}
        />
        <Route
          path="/centmeasurement"
          element={<CentMeasurement appState={this.state} />}
        />
        <Route
          path="/triangles"
          element={<Triangles appState={this.state} />}
        />
        <Route
          path="/multiples"
          element={<Multiples appState={this.state} />}
        />
        <Route path="/volume" element={<Volume appState={this.state} />} />
        <Route
          path="/multiplication"
          element={<Multiplication appState={this.state} />}
        />
        <Route
          path="/prismspyramids"
          element={<PrismsPyramids appState={this.state} />}
        />
        <Route
          path="/transrotreflect"
          element={<TransRotReflect appState={this.state} />}
        />
        <Route path="/symmetry" element={<Symmetry appState={this.state} />} />
        <Route path="/mapscale" element={<MapScale appState={this.state} />} />
        <Route
          path="/numberchart"
          element={<NumberChart appState={this.state} />}
        />
        <Route path="/circles" element={<Circles appState={this.state} />} />
        <Route path="/area" element={<Area appState={this.state} />} />
        <Route path="/percents" element={<Percents appState={this.state} />} />
        <Route
          path="/squarenumbers"
          element={<SquareNumbers appState={this.state} />}
        />
        <Route
          path="/setfractions"
          element={<SetFractions appState={this.state} />}
        />
        <Route path="/pplines" element={<PPLines appState={this.state} />} />
        <Route
          path="/perimeter"
          element={<Perimeter appState={this.state} />}
        />
        <Route path="/division" element={<Division appState={this.state} />} />
        <Route path="*" element={<Home appState={this.state} />} />
      </Routes>
    );
  }
}

export default App;
