import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import volume from "./images/volume1.png";
import volume2 from "./images/sub-images/volume-activity-3.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Volume extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Volume";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Volume
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={volume} alt="volume math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                Students particularly enjoy this Math Art project. They learn to
                calculate the volume of nine nested boxes (which can also be
                converted into a 45-centimeter tall tower).
              </p>
              <p>
                Before students begin working, explain to them how volume is
                related to what they learned about perimeter and area. Perimeter
                is measured using linear units. Area is measured using square
                units. Volume is measured using cube units. Draw examples of all
                three on the board.
              </p>
              <p className="center"><img src={volume2} /></p>
              <p>
              Explain to students that volume is “3D” because it measures three dimensions (or three “directions”). Show them a completed set of nine nested boxes (previously constructed by the teacher) and explain how each box has the dimensions of length, width, and height. If we multiply the three dimensions of a box, we discover how many cubes can fit inside. The number of cubes that can fit inside a box is called its volume.
              </p>
              <p>
              Next, the teacher should demonstrate how the nine nested boxes can be converted into a tower. Ask them, “Which box is the biggest?” If they answer the wide one on the bottom, say “What do you mean? That box is so flat!” If they answer the tall one on top, say, “Really? That box is so skinny!” The point is to remind them that every box has three dimensions—some of the boxes are larger in length and width, while others are larger in height. The only way we can really find out which box is “biggest” is by figuring out how many cubes each one can hold; in other words, calculating the volume of each.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Volume
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={volume} alt="volume math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                Students particularly enjoy this Math Art project. They learn to
                calculate the volume of nine nested boxes (which can also be
                converted into a 45-centimeter tall tower).
              </p>
              <p>
                Before students begin working, explain to them how volume is
                related to what they learned about perimeter and area. Perimeter
                is measured using linear units. Area is measured using square
                units. Volume is measured using cube units. Draw examples of all
                three on the board.
              </p>
              <p className="center"><img src={volume2} className="smallerWidth" /></p>
              <p>
              Explain to students that volume is “3D” because it measures three dimensions (or three “directions”). Show them a completed set of nine nested boxes (previously constructed by the teacher) and explain how each box has the dimensions of length, width, and height. If we multiply the three dimensions of a box, we discover how many cubes can fit inside. The number of cubes that can fit inside a box is called its volume.
              </p>
              <p>
              Next, the teacher should demonstrate how the nine nested boxes can be converted into a tower. Ask them, “Which box is the biggest?” If they answer the wide one on the bottom, say “What do you mean? That box is so flat!” If they answer the tall one on top, say, “Really? That box is so skinny!” The point is to remind them that every box has three dimensions—some of the boxes are larger in length and width, while others are larger in height. The only way we can really find out which box is “biggest” is by figuring out how many cubes each one can hold; in other words, calculating the volume of each.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Volume;
