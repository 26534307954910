import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import inchMeasurement from "./images/measurement2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Time extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Inch Measurement";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Inch Measurement
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={inchMeasurement} alt="measurement math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson teaches students to measure lengths using inches.
                More specifically, students must accurately measure the
                following lengths:
              </p>
              <p style={{textAlign: "center"}}>4 ½ inches<br/>6 inches<br/>7 ½ inches<br/>9 inches<br/>10 ½ inches</p>
              <p>
                Measuring with a ruler may seem easy to an adult, but young and
                inexperience students often find it difficult. Here are some of
                the most common mistakes students make when measuring:
              </p>
              <ul className="normal">
                <li>They fail to correctly line up the zero.</li>
                <li>
                  They line up the zero correctly, but then inadvertently shift
                  the ruler out of place when searching for the appropriate
                  measurement.
                </li>
                <li>
                  When finding measurements that include halves, they forget
                  about the half. For instance, instead of measuring 9 ½ inches,
                  they measure 9 inches. Or instead of measuring 6 ½ inches,
                  they measure 6 inches.
                </li>
                <li>
                  They confuse halfway measurements with quarter measurements.
                </li>
              </ul>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Inch Measurement
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={inchMeasurement} alt="measurement math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
            <p>
                This lesson teaches students to measure lengths using inches.
                More specifically, students must accurately measure the
                following lengths:
              </p>
              <p style={{textAlign: "center"}}>4 ½ inches<br/>6 inches<br/>7 ½ inches<br/>9 inches<br/>10 ½ inches</p>
              <p>
                Measuring with a ruler may seem easy to an adult, but young and
                inexperience students often find it difficult. Here are some of
                the most common mistakes students make when measuring:
              </p>
              <ul>
                <li>They fail to correctly line up the zero.</li>
                <li>
                  They line up the zero correctly, but then inadvertently shift
                  the ruler out of place when searching for the appropriate
                  measurement.
                </li>
                <li>
                  When finding measurements that include halves, they forget
                  about the half. For instance, instead of measuring 9 ½ inches,
                  they measure 9 inches. Or instead of measuring 6 ½ inches,
                  they measure 6 inches.
                </li>
                <li>
                  They confuse halfway measurements with quarter measurements.
                </li>
              </ul>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Time;
