import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import addition from "./images/addition.png";
import addition2 from "./images/sub-images/addition-activity-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Addition extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Addition";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Addition
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={addition} alt="addition math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                The goal of this project is to help students better understand
                addition by having them deconstruct the number 999 into addends
                of their choice. It also gives them practice in reading base-10
                blocks.
              </p>
              <p>
                Prior to the day of the project, the teacher must prepare a
                piece of 12" x 18" construction paper for each student. Use the
                method discussed at the beginning of the Money Activity (page 5)
                to simultaneously mark multiple pieces of construction paper
                directly in the center with a single guide dot.
              </p>
              <p className="center"><img src={addition2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Addition
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={addition} alt="addition math art project" style={{maxWidth: "270px"}}/>
            <div style={{ textAlign: "left" }}>
              <p>
              The goal of this project is to help students better understand addition by having them deconstruct the number 999 into addends* of their choice. It also gives them practice in reading base-10 blocks.
              </p>
              <p>
              Prior to the day of the project, the teacher must prepare a piece of 12" x 18" construction paper for each student. Use the method discussed at the beginning of the Money Activity (page 5) to simultaneously mark multiple pieces of construction paper directly in the center with a single guide dot.
              </p>
              <p className="center"><img className="smallerWidth" src={addition2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Addition;
