import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import numberChart from "./images/chart2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class NumberChart extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Number Chart";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            The 100 Number Chart
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={numberChart} alt="number chart math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This project helps students recognize patterns in 1-100 and/or
                10-1000 number charts, especially less obvious vertical
                patterns. Before starting the project, ask students "What are we
                counting by if we start at the number 4 and move our finger down
                the chart?" The class can count with you: 4, 14, 24, 34, etc.
                Some students are likely to state that we are counting by 4's
                instead of 10's. These students are the ones the teacher needs
                to focus your attention on once the project begins, since they
                are either not recognizing the vertical pattern, or worse, do
                not understand place value.
              </p>
              <p>
                The project has two parts, the first being more instructionally
                significant. For the first part, students make number chart
                puzzles, which they can lend to other students to try and solve.
                The second part, where they glue their scrambled puzzle pieces
                randomly onto construction paper, is mainly a reward for
                finishing.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            The 100 Number Chart
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={numberChart} alt="number chart math art project" style={{width: "270px"}}/>
            <div style={{ textAlign: "left" }}>
              <p>
                This project helps students recognize patterns in 1-100 and/or
                10-1000 number charts, especially less obvious vertical
                patterns. Before starting the project, ask students "What are we
                counting by if we start at the number 4 and move our finger down
                the chart?" The class can count with you: 4, 14, 24, 34, etc.
                Some students are likely to state that we are counting by 4's
                instead of 10's. These students are the ones the teacher needs
                to focus your attention on once the project begins, since they
                are either not recognizing the vertical pattern, or worse, do
                not understand place value.
              </p>
              <p>
                The project has two parts, the first being more instructionally
                significant. For the first part, students make number chart
                puzzles, which they can lend to other students to try and solve.
                The second part, where they glue their scrambled puzzle pieces
                randomly onto construction paper, is mainly a reward for
                finishing.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default NumberChart;
