import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import multiples from "./images/multiples.png";
import multiples2 from "./images/sub-images/common-multiples-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Multiples extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Multiples";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Multiples
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={multiples} alt="multiples math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson aims primarily to build students' understanding of
                multiples, specifically the difference between "common"
                multiples and "uncommon" multiples. The lesson also reinforces
                understanding of multiplication facts (4x0 = 0, 4x1 = 4, 4x2 =
                8, etc.).
              </p>
              <p>
                Multiples are the numbers that occur when one skip counts (for
                instance, the multiples of 4 are 0, 4, 8, etc.). The topic of
                multiples is normally taught using separate 0-99 number charts.
                Typically, students complete a multiples of 2 chart, a multiples
                of 3 chart, a multiples of 4 chart, etc.
              </p>
              <p className="center"><img src={multiples2} /></p>
              <p>
              The strength of this traditional method is that it allows for generalized comparisons of number chart patterns. However, the limitation of this method is that it hinders students' ability to make more specific comparisons. For instance, if students wanted to see how frequently the number 24 appears as a multiple, they would need to flip through and observe many different number charts. In other words, finding how frequently the number 24 appears as a multiple, or how rarely the number 13 appears as a multiple, is inconvenient.
              </p>
              <p>
              The Math Art method of teaching multiples supplements the traditional method by consolidating separate charts onto a single (albeit large) sheet of paper. Furthermore, the contents of each chart now appear as unbroken, parallel number lines, making individual number analysis much easier (see picture on page 17).
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Multiples
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={multiples} alt="telling time math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson aims primarily to build students' understanding of
                multiples, specifically the difference between "common"
                multiples and "uncommon" multiples. The lesson also reinforces
                understanding of multiplication facts (4x0 = 0, 4x1 = 4, 4x2 =
                8, etc.).
              </p>
              <p>
                Multiples are the numbers that occur when one skip counts (for
                instance, the multiples of 4 are 0, 4, 8, etc.). The topic of
                multiples is normally taught using separate 0-99 number charts.
                Typically, students complete a multiples of 2 chart, a multiples
                of 3 chart, a multiples of 4 chart, etc.
              </p>
              <p className="center"><img className="smallerWidth" src={multiples2} /></p>
              <p>
              The strength of this traditional method is that it allows for generalized comparisons of number chart patterns. However, the limitation of this method is that it hinders students' ability to make more specific comparisons. For instance, if students wanted to see how frequently the number 24 appears as a multiple, they would need to flip through and observe many different number charts. In other words, finding how frequently the number 24 appears as a multiple, or how rarely the number 13 appears as a multiple, is inconvenient.
              </p>
              <p>
              The Math Art method of teaching multiples supplements the traditional method by consolidating separate charts onto a single (albeit large) sheet of paper. Furthermore, the contents of each chart now appear as unbroken, parallel number lines, making individual number analysis much easier (see picture on page 17).
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Multiples;
