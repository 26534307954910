import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import placeValue from "./images/placeValue.png";
import placeValue2 from "./images/sub-images/place-value-activity-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class PlaceValue extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Place Value";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Place Value
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={placeValue} alt="place value math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This lesson helps students memorize place values. Furthermore,
                and perhaps more importantly, it lays the groundwork for decimal
                instruction, which is one of the most difficult topics to teach
                in elementary mathematics. Why are decimals so difficult?
              </p>

              <ul className="normal">
                <li>
                  They complicate fraction instruction by placing importance on
                  a limited number of denominators (tenths, hundredths,
                  thousandths, etc).
                </li>
                <li>
                  Students easily confuse them with negative numbers, since both
                  topics introduce students to a set of numbers they never knew
                  existed, namely numbers less than 1.
                </li>
                <li>Nine thousandths is less than one tenth? Why is that?</li>
                <li>There is no "oneths" place.</li>
                <li>
                  Unlike other place values, decimal place values are not
                  separated into groups of three by commas
                </li>
              </ul>
              <p>In many classrooms, decimal place value is taught using base-10 blocks that look literally identical to the base-10 blocks used to teach “regular” place value.</p>
              <p className="center"><img src={placeValue2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Place Value
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={placeValue} alt="place value math art project" style={{width: "270px"}} />
            <div style={{ textAlign: "left" }}>
            <p>
                This lesson helps students memorize place values. Furthermore,
                and perhaps more importantly, it lays the groundwork for decimal
                instruction, which is one of the most difficult topics to teach
                in elementary mathematics. Why are decimals so difficult?
              </p>

              <ul>
                <li>
                  They complicate fraction instruction by placing importance on
                  a limited number of denominators (tenths, hundredths,
                  thousandths, etc).
                </li>
                <li>
                  Students easily confuse them with negative numbers, since both
                  topics introduce students to a set of numbers they never knew
                  existed, namely numbers less than 1.
                </li>
                <li>Nine thousandths is less than one tenth? Why is that?</li>
                <li>There is no "oneths" place.</li>
                <li>
                  Unlike other place values, decimal place values are not
                  separated into groups of three by commas
                </li>
              </ul>
              <p>In many classrooms, decimal place value is taught using base-10 blocks that look literally identical to the base-10 blocks used to teach “regular” place value.</p>
              <p className="center"><img className="smallerWidth" src={placeValue2} /></p>
              
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default PlaceValue;
