import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import fractions from "./images/fractions.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Fractions extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Fractions";
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Fractions
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={fractions} alt="fractions math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
                This project is designed to be students' first introduction to
                the topic of fractions. More specifically, it introduces
                students the terms used to describe faction denominators
                ("halves," "thirds," "fourths," etc.).
              </p>
              <p>
                Too often, teachers begin fraction instruction with examples
                consistently showing a small numerator and a large denominator.
                Before long, students believe that numerators can never be
                larger than denominators. This shallow understanding leads to
                confusion once improper fractions are introduced.
              </p>
              <p>
              The teacher can avoid this problem by waiting to teach these words. The best place to start fraction instruction is with the simple idea of breaking apart a whole. If we break a whole into four parts, than each of those parts is called a fourth. If we lose one of those parts, we only have three fourths left. If we find two more, we have five fourths. In time, students can be adding and subtracting proper and improper fractions before ever even hearing the words numerator and denominator.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Fractions
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={fractions} alt="fractions math art project" style={{maxWidth: "270px"}} />
            <div style={{ textAlign: "left" }}>
              <p>
                This project is designed to be students' first introduction to
                the topic of fractions. More specifically, it introduces
                students the terms used to describe faction denominators
                ("halves," "thirds," "fourths," etc.).
              </p>
              <p>
                Too often, teachers begin fraction instruction with examples
                consistently showing a small numerator and a large denominator.
                Before long, students believe that numerators can never be
                larger than denominators. This shallow understanding leads to
                confusion once improper fractions are introduced.
              </p>
              <p>
              The teacher can avoid this problem by waiting to teach these words. The best place to start fraction instruction is with the simple idea of breaking apart a whole. If we break a whole into four parts, than each of those parts is called a fourth. If we lose one of those parts, we only have three fourths left. If we find two more, we have five fourths. In time, students can be adding and subtracting proper and improper fractions before ever even hearing the words numerator and denominator.
              </p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Fractions;
