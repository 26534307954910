import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import money from "./images/money.png";
import money2 from "./images/sub-images/money-activity-2.png";
import amazon from "./images/amazon3.png";
import activityBook from "./images/activityBook.png";

class Money extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.title = "Math Art Projects | Money"
  };

  render() {
    /////// DESKTOP //////////

    if (this.props.appState.size === "desktop") {
      return (
        <div className="desktop">
          <h1
            style={{ fontSize: "28pt", fontWeight: "600", marginBottom: "0px" }}
          >
            Money
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
              marginLeft: "15%",
              marginRight: "15%",
            }}
          >
            <img src={money} alt="money math art project" />
            <div style={{ textAlign: "left" }}>
              <p>
              This lesson helps students visualize and memorize how many quarters, dimes, nickels, and pennies, make up a dollar. The project is an abstract representation of five different ways of making 100 cents: a dollar bill, 4 quarters, 10 dimes, 20 nickels, and 100 pennies.
              </p>
              <p>
              Although this lesson is more time consuming than many of the others, it's still worth students' time. Not only does it lay the groundwork for future lesson on money, it's also a highly motivating way to get students thinking about multiplication and division. Understanding that 20 nickels equals a dollar (20 x 5 = 100, 100 ÷ 5 = 20) is a great first step towards understanding the components of future multiplication and division problems.
              </p>
              <p>
              Before the lesson, the teacher must prepare a 12" x 18" sheet of construction paper for each student. Each student's sheet needs 16 dots. The sheets can be prepared all at once with the help of a guide sheet. To prepare the guide sheet, use a ruler to layout exactly where dots are needed on the construction paper:
              </p>
              <p className="center"><img src={money2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", width: "500px", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "300px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }

    /////// MOBILE //////////
    else if (this.props.appState.size === "mobile") {
      return (
        <div className="mobile">
          <h1
            style={{
              fontSize: "28pt",
              fontWeight: "600",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            Money
          </h1>
          <br />
          <div
            style={{
              textAlign: "center",
              fontSize: "14pt",
            }}
          >
            <img src={money} alt="money math art project" style={{maxWidth: "270px"}} />
            <div style={{ textAlign: "left" }}>
            <p>
              This lesson helps students visualize and memorize how many quarters, dimes, nickels, and pennies, make up a dollar. The project is an abstract representation of five different ways of making 100 cents: a dollar bill, 4 quarters, 10 dimes, 20 nickels, and 100 pennies.
              </p>
              <p>
              Although this lesson is more time consuming than many of the others, it's still worth students' time. Not only does it lay the groundwork for future lesson on money, it's also a highly motivating way to get students thinking about multiplication and division. Understanding that 20 nickels equals a dollar (20 x 5 = 100, 100 ÷ 5 = 20) is a great first step towards understanding the components of future multiplication and division problems.
              </p>
              <p>
              Before the lesson, the teacher must prepare a 12" x 18" sheet of construction paper for each student. Each student's sheet needs 16 dots. The sheets can be prepared all at once with the help of a guide sheet. To prepare the guide sheet, use a ruler to layout exactly where dots are needed on the construction paper:
              </p>
              <p className="center"><img className="smallerWidth" src={money2} /></p>
              <br />
            </div>
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                src={amazon}
                alt="Buy book on Amazon"
                style={{ width: "200px" }}
              />
            </a>
            <br />
            <br />

            <div style={{ fontSize: "18pt", fontWeight: "600" }}>
              The complete activity book includes:
            </div>
            <br />
            <ul style={{ textAlign: "left", margin: "auto" }}>
              <li style={{ color: "yellow" }}>
                27 arts-integrated math lessons
              </li>
              <li style={{ color: "violet" }}>
                Easy-to-photocopy blackline masters
              </li>
              <li style={{ color: "yellow" }}>
                Back-of-the-book index of key terms
              </li>
              <li style={{ color: "violet" }}>
                Recommended grade-level breakdown
              </li>
              <li style={{ color: "yellow" }}>
                NCTM Standards Alignment Chart
              </li>
              <li style={{ color: "violet" }}>
                Clearly stated lesson objectives
              </li>
              <li style={{ color: "yellow" }}>
                Easy-to-follow project descriptions and diagrams
              </li>
              <li style={{ color: "violet" }}>
                Reproducible assessment sheets for every lesson (requiring
                students to explain their mathematical thinking in writing)
              </li>
            </ul>
            <br />
            <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439/">
              <img
                style={{ width: "270px" }}
                src={activityBook}
                alt="math art activity book"
              />
            </a>
            <br />
            <br />
            <div style={{ fontSize: "12pt" }}>
              Copyright © 2022 by Zachary J. Brewer
            </div>
            <br />
            <div style={{ fontSize: "12pt" }}>
              <Link to="/">← MathArtProjects.com</Link>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Money;
